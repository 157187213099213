import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useContext } from 'react'
import { createCustomeTheme } from '../src/theme'
import AppRouter from './AppRouter'
import SettingsContext from './context/SettingsContext'

function App() {
  const themeSetting = useContext(SettingsContext)
  const theme = createCustomeTheme({
    theme: createTheme(themeSetting.settings.theme),
  })

  // Get today's date and set the time to 12:00 PM
  // const now = new Date()
  // const targetDate = new Date(
  //   now.getFullYear(),
  //   now.getMonth(),
  //   now.getDate(),
  //   11,
  //   20,
  //   0
  // ).getTime()

  // const [countdown, setCountdown] = useState(targetDate - new Date().getTime())
  // const [isSiteRevealed, setIsSiteRevealed] = useState(false)

  // useEffect(() => {
  //   if (countdown > 0) {
  //     const timer = setInterval(() => {
  //       const remainingTime = targetDate - new Date().getTime()
  //       setCountdown(remainingTime)
  //     }, 1000)

  //     return () => clearInterval(timer) // Cleanup the interval on component unmount
  //   } else {
  //     setIsSiteRevealed(true) // Show the site once countdown is over
  //   }
  // }, [countdown])

  // useEffect(() => {
  //   window.scrollTo(0, 0) // Reset scroll position when the component mounts
  // }, [])

  // // Calculate remaining time in days, hours, minutes, and seconds
  // const calculateTimeLeft = () => {
  //   const days = Math.floor(countdown / (1000 * 60 * 60 * 24))
  //   const hours = Math.floor(
  //     (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   )
  //   const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60))
  //   const seconds = Math.floor((countdown % (1000 * 60)) / 1000)

  //   return { days, hours, minutes, seconds }
  // }

  // const { days, hours, minutes, seconds } = calculateTimeLeft()

  // const countdownStyle = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: '100vh', // Full viewport height
  //   backgroundColor: '#30B38A',
  //   color: 'white',
  //   textAlign: 'center',
  //   fontSize: '2rem',
  //   fontFamily: 'Alata, sans-serif',
  // }

  // // Styling for the "Something Wonderful" message (Figma design)
  // const messageStyle = {
  //   fontFamily: 'Alata, sans-serif',
  //   fontSize: '96px',
  //   fontWeight: 400,
  //   lineHeight: '132.48px',
  //   letterSpacing: '0.07em',
  //   textAlign: 'center',
  //   marginBottom: '30px', // Adjust for space between message and timer
  // }

  // const timerStyle = {
  //   fontSize: '2rem',
  //   fontWeight: 'bold',
  // }

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

export default App
