import {
  IconButton,
  TextField,
  Typography,
  Container,
  Box,
  Dialog,
  DialogContent,
} from '@mui/material'
import React, { useState } from 'react'
import useCustomStyles from '../../customehooks/useCustomeStyles'
import { useTheme } from '@mui/material/styles'
import { IoIosArrowForward } from 'react-icons/io'
import { LuPhone } from 'react-icons/lu'
import { FiMail } from 'react-icons/fi'
import { SiFacebook } from 'react-icons/si'
import { RxInstagramLogo } from 'react-icons/rx'
import Terms from '../../pages/TermsAndConditions/Terms'
import { useNavigate } from 'react-router-dom'
import ContactUs from '../../pages/AsanaDetails/ContactUs'
import { subscribeEmail } from '../../utils/api'

const styles = () => ({
  footer: {
    // background: "url(/images/footer-bg-cropped.png)",

    '& h3': {
      fontSize: '28px',
      fontFamily: 'Amethysta',
      LineHeight: '33.73px',
    },

    '& .forwardbtn': {
      width: '60px',
      height: '60px',
      background: '#262626',
      borderRadius: '50%',
    },

    '& .textfield': {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '446px',
      height: '60px',

      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
        background: 'transparent',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #262626',
      },

      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#262626',
      },

      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: '#667085',
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '24.84px',
          fontFamily: 'Alata',
        },
      },
    },
  },
})

//footer links
const footerData = [
  {
    topic: 'Important Links',
    subTopics: [
      { icon: '', title: 'Schedule & Booking' },
      { icon: '', title: 'Book a Class' },
    ],
  },

  {
    topic: 'Class Types',
    subTopics: [
      { icon: '', title: 'Asana' },
      { icon: '', title: 'Meditation' },
      { icon: '', title: 'Yoga Nidra' },
      { icon: '', title: 'Mantra' },
      { icon: '', title: 'Mudras' },
      { icon: '', title: 'Pranayama' },
    ],
  },

  {
    topic: 'Legal Terms',
    subTopics: [
      { icon: '', title: 'FAQs' },
      { icon: '', title: 'Terms and Conditions' },
    ],
  },

  {
    topic: 'Contact Us',
    subTopics: [
      { icon: <LuPhone />, title: '+919632572111' },
      {
        icon: <FiMail />,
        title: (
          <a
            href='mailto:contact@yujbysurekha.co.in'
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            contact@yujbysurekha.co.in
          </a>
        ),
      },
    ],
  },
]

export default function Footer() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openTermsDialog, setOpenTermsDialog] = useState(false)

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true)
  }

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleNavigate = (title) => {
    console.log(title)
    navigate(`/asana-info?title=${title}`)
  }

  const handleNavigateAndScrollToTop = (url) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(url)
  }

  const handleNavigateAndScrollToFAQ = (url) => {
    window.scrollTo({ top: 5900, behavior: 'auto' })
    navigate(url)
  }

  const handleSubscribe = async () => {
    console.log('hit')
    if (!email) {
      setError('Please enter an email address.')
      return
    }

    setLoading(true)
    setError(null)

    try {
      const response = await subscribeEmail(email)
      console.log(response)
      alert(response.message)
      setEmail('')
    } catch (err) {
      if (err.errors) {
        setError(err.errors.join(' '))
      } else if (err.error) {
        setError(err.error)
      } else {
        setError('An unexpected error occurred.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <footer className={`${classes?.footer} pt-[115px] pb-[115px]`}>
        <Container maxWidth='lg'>
          {/* <div className='flex items-center md:justify-end justify-start flex-wrap gap-[17px] mb-[74px]'>
            <Typography
              className='pr-[2%] md:pr-0'
              variant='h3'
              color='primary.dark'
              fontWeight={400}
            >
              Subscribe
            </Typography>

            <div className='flex items-center gap-[17px]'>
              <TextField
                variant='outlined'
                className='textfield'
                placeholder='Email address'
                value={email}
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: '#000 !important',
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />

              <IconButton
                className='forwardbtn'
                onClick={handleSubscribe}
                disabled={loading}
              >
                <IoIosArrowForward color='white' />
              </IconButton>
            </div>

            {error && (
              <Typography color='error' className='mt-2'>
                {error}
              </Typography>
            )}
          </div> */}

          <div className='grid grid-grid-col sm:grid-cols-2 lg:grid-cols-4 mb-[92px]'>
            {footerData.map((data) => (
              <div key={data.topic}>
                <Typography variant='h3' fontWeight={400} color='primary.dark'>
                  {data.topic}
                </Typography>

                <ul className='mt-8'>
                  {data.subTopics.map((subtopic, index) => (
                    <Box key={index} mb={4}>
                      <Typography
                        key={index}
                        variant='body1'
                        sx={{
                          color: '#262626',
                          cursor:
                            subtopic.title === 'Terms and Conditions' ||
                            subtopic.title === 'FAQs' ||
                            subtopic.title === 'Schedule & Booking' ||
                            subtopic.title === 'Book a Class' ||
                            data.topic === 'Class Types'
                              ? 'pointer'
                              : 'default',
                          '&:hover': {
                            textDecoration:
                              subtopic.title === 'Terms and Conditions' ||
                              subtopic.title === 'FAQs' ||
                              subtopic.title === 'Schedule & Booking' ||
                              subtopic.title === 'Book a Class' ||
                              data.topic === 'Class Types'
                                ? 'underline'
                                : 'none',
                          },
                        }}
                        onClick={() => {
                          if (subtopic.title === 'Terms and Conditions') {
                            handleOpenTermsDialog()
                          } else if (subtopic.title === 'Book a Class') {
                            handleClickOpen()
                          } else if (subtopic.title === 'FAQs') {
                            handleNavigateAndScrollToFAQ('/')
                          } else if (subtopic.title === 'Schedule & Booking') {
                            handleNavigateAndScrollToTop('/schedule-and-timing')
                          } else if (data.topic === 'Class Types') {
                            handleNavigate(subtopic.title)
                          }
                        }}
                        className='flex items-center gap-4'
                      >
                        {subtopic.icon !== '' && subtopic.icon} {subtopic.title}
                      </Typography>
                    </Box>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className='flex justify-between items-center flex-wrap gap-[20px] lg:gap-[0px]'>
            <div>
              <Typography variant='h6' color='primary.dark'>
                Copyright & Trademark © 2024 - All Rights Reserved.
              </Typography>
            </div>

            <div className='flex items-center gap-[20px]'>
              <a
                href='https://www.facebook.com/profile.php?id=61567636641278'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Facebook' // Accessibility improvement
              >
                <SiFacebook className='w-[30px] h-[30px] cursor-pointer' />
              </a>
              <a
                href='https://www.instagram.com/yuj_by_surekha/'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Instagram' // Accessibility improvement
              >
                <RxInstagramLogo className='w-[30px] h-[30px] cursor-pointer' />
              </a>
            </div>
          </div>
          <Terms open={openTermsDialog} onClose={handleCloseTermsDialog} />
          <Dialog open={open} onClose={handleClose} maxWidth='md'>
            <DialogContent sx={{ backgroundColor: '#E0E5B6' }}>
              <ContactUs onClose={handleClose} dialogue={true} />
            </DialogContent>
          </Dialog>
        </Container>
      </footer>
      <Box display='flex' justifyContent='center' py={2} bgcolor='#1A2130'>
        <Typography
          variant='subtitle2'
          sx={{
            color: '#5A72A0',
            textAlign: 'center',
            fontFamily: "'Archivo', sans-serif",
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 0.5,
          }}
        >
          Website designed and developed by{' '}
          <a
            href='https://forgeandflowsolutions.com/'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#83B4FF',
              fontFamily: "'Archivo', sans-serif",
              textDecoration: 'none',
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-2px)'
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0)'
            }}
          >
            <strong>Forge & Flow Solutions © 2024</strong>
          </a>
        </Typography>
      </Box>
    </>
  )
}
